<template>

<div class="partner-wrapper">
    <div class="partner-container">
        <div class="partner-left">
            <ul><li></li></ul>
        </div>
        <div class="partner partner-center">
            <p>
                Wir sind ein Partner der Siemens Austria AG, im Detail mit der Abteilung DI CS,  wir übernehmen hier im sub Aufträge primär im OT-Netzwerk und -Security Bereich.
                Wir sind geschult im Konfigurieren und Betreiben der Siemens Infrastruktur in diesen Bereichen und vertreiben sie auch.
            </p>
        </div>
        <div class="partner partner-right">
            <div class="partner-button-wrapper" >
                <button 
                    class="partner-button"
                    :class="[`partner-button-${theme}`]"
                    @click.prevent="openSiemens"
                >Siemens</button>
            </div>
        </div>
    </div>
    <div class="partner-container">
        <div class="partner-left">
            <ul><li></li></ul>
        </div>
        <div class="partner partner-center">
            <p>
                Als Business-Lösung für Wissensmanagement/Wikis wind wir in Partnerschaft mit Linkyard, die Phonemos als Produkt anbieten. 
                Der große Vorteil von Phonemos ist, dass es sowohl On-Premise, als auch als Cloud-Lösung als SaaS angeboten wird und dabei auf bekannte Industriestandards aufsetzt.
                Weiters stehen Skripte zur Verfügung, um von den meißten gängigen Wissensmanagement-Lösungen auf Phonemos zu wechseln.
            </p>
        </div>
        <div class="partner partner-right">
            <div class="partner-button-wrapper" >
                <button 
                    class="partner-button"
                    :class="[`partner-button-${theme}`]"
                    @click.prevent="openPhonemos"
                >Phonemos</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';


export default {

    setup() {
        const theme = ref(localStorage.getItem('theme') || 'light');
        const $bus = inject('$bus');
        
        onMounted(() => {
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
        });
        
        function openSiemens() {
            window.open(`https://www.siemens.com/at/de.html`, '_blank', 'noopener,noreferrer');
        }
        function openPhonemos() {
            window.open(`https://www.phonemos.com/`, '_blank', 'noopener,noreferrer');
        }

        return { theme,
                 openSiemens, openPhonemos
        };
    },
}

</script>

<style scoped>

.partner-container{
    width: 1000px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    display: flex;
}

.partner{
    min-width: 200px;
}

.partner-left{
    padding-top: 10px;
    width: 50px;
    align-items: center;
    font-size: larger;
}

.partner-right{
    padding-top: 10px;
    text-align: center;
    font-size: larger;
}


.partner-button {
    background-color: #AAA;
    border-radius: 5px;
    align-content: center;
    font-size: large;
}
.partner-button-dark {
    background-color: #888;
}
.partner-button-dark:hover {
    box-shadow: 5px 5px 5px #AAA inset ;
}
.partner-button-light:hover {
    box-shadow: 5px 5px 5px #888 inset ;
}

</style>