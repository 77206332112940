<template>
<hr>
<div class="footer container">
    <div class="footer-container">
        <div class="col">
            © 2024 by Thrill IT GmbH 
            <router-link
                class="nav-link"
                aria-current="page" 
                to="/news"
                :style="{ color: themeColor }"
            >News</router-link>
        </div>
        <div class="col">
            <router-link
                class="nav-link"
                aria-current="page" 
                to="/partner"
                :style="{ color: `${themeColor}` }"
            >Partner</router-link>
        </div>
        <div class="col">
            <router-link
                class="nav-link"
                aria-current="page" 
                to="/impressum"
                :style="{ color: `${themeColor}` }"
            >Impressum</router-link>
        </div>
    </div>
</div>
</template>

<script>

export default {
    inject: ['$bus'],

    data() {
        return {
            currentTheme: localStorage.getItem('theme')
        }
    },

    created() {
        this.$bus.$on('theme-change', this.updateTheme);
    },

    computed: {
        themeColor() {
            return this.currentTheme == "dark" ? "aqua" : "blue";
        }
    },

    methods: {
        updateTheme() {
            this.currentTheme = localStorage.getItem('theme');
        }
    }
}

</script>

<style scoped>

.footer {
    font-size: small;
    justify-content: center;
    align-items: center;
}

.footer-container {
    display: flex;
    width: 100%;
}

.col {
    flex: 1 1 30%;
    padding-left: 10px;
    padding-right: 10px;
}

.col:not(:last-child) {
    border-right: 2px solid #000;
}

@media (max-width: 600px) {
    .footer-container {
        flex-wrap: wrap;
    }
    .col {
        flex: 1 1 100%;
    }
    .col:not(:last-child) {
        border-right: none;
        border-bottom: 2px solid #000;
    }
}

</style>