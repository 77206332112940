<template>

<div id="home-content" :class="['container', {'blur': showModal() } ]">
    <div class="title-container">
        <div class="image title-image image-logo">
            <img :src="require('@/assets/Logo.png')" alt="Thrill IT Logo" width="384">
        </div>
        <div class="image title-image image-network">
            <img :src="require('@/assets/network.png')" width="512"> 
        </div> 
    </div>
    <div class="container company-description">
        <h1> Thrill IT GmbH     </h1>
        <p>  IT, die begeistert; <br/>
             OT, die absichert!</p>
    </div>
    <h2>Letzte <router-link
                class="nav-link inline-link"
                aria-current="page" 
                to="/news"
                :style="{ color: 'green' }"
            >News</router-link>:</h2>
    
    <div class="news-content" :class="[`theme-box-${theme}`]">
        <h3>{{ latestNews.date }} - {{ latestNews.title }}</h3>
        <div v-html="latestNews.content"></div>
    </div>

    <div class="section offer-content">
        <div class="section-band">
            <div class="section-col"></div>
            <div class="section-col"></div>
        </div>
        <div class="section-title">
            <h2>Unser Angebot:</h2>
        </div>
        <div class="offer-grid">
            <div class="offer-row row">
                <div class="offer-col col" :class="[`theme-box-${theme}`]">
                    <div class="offer-title">
                        <div class="offer-title-row-1">
                            <img :src="require('@/assets/network_icon.png')" width="64">
                        </div>
                        <div class="offer-title-row-2">
                            <h4>Netzwerk</h4>
                        </div>
                    </div>
                    <div class="offer-description">
                        <p>Switches, Router, VPN, WLAN, SDN und Verkabelung(Kupfer/LWL)</p>
                    </div>
                </div>
                <div class="offer-col col" :class="[`theme-box-${theme}`]">
                    <div class="offer-title">
                        <div class="offer-title-row-1">
                            <img :src="require('@/assets/sec.png')" width="64">
                        </div>
                        <div class="offer-title-row-2">
                            <h4>IT/OT security</h4>
                        </div>
                    </div>
                    <div class="offer-description">
                        <p>Hardware wie Firewalls oder Loadbalancers, Software wie Whitelisting oder Anomalieerkennung</p>
                    </div>
                </div>
                <div class="offer-col col" :class="[`theme-box-${theme}`]">
                    <div class="offer-title">
                        <div class="offer-title-row-1">
                            <img :src="require('@/assets/ai.png')" width="64">
                        </div>
                        <div class="offer-title-row-2">
                            <h4>AI in der OT</h4>
                        </div>
                    </div>
                    <div class="offer-description">
                        <p>AI-Service als on-Premise Lösungen, sodass sie auch in der OT implementiert werden können</p>
                    </div>
                </div>
            </div>

            <div class="offer-row row">
                <div class="offer-col col" :class="[`theme-box-${theme}`]">
                    <div class="offer-title">
                        <div class="offer-title-row-1">
                            <img :src="require('@/assets/infra.png')" width="64">
                        </div>
                        <div class="offer-title-row-2">
                            <h4>Infrastruktur</h4>
                        </div>
                    </div>
                    <div class="offer-description">
                        <p>Virtualisierte Umgebungen, Hardware Ausstattung, NAS/SAN Lösungen, Backup und Monitoring</p>
                    </div>
                </div>
                <div class="offer-col col" :class="[`theme-box-${theme}`]">
                    <div class="offer-title">
                        <div class="offer-title-row-1">
                            <img :src="require('@/assets/consult.png')" width="64">
                        </div>
                        <div class="offer-title-row-2">
                            <h4>IT/OT Consulting</h4>
                        </div>
                    </div>
                    <div class="offer-description">
                        <p>Planung, Bedarfserhebung, Projektleitung/-koordination und Umsetzung</p>
                    </div>
                </div>
                <div class="offer-col col" :class="[`theme-box-${theme}`]">
                    <div class="offer-title">
                        <div class="offer-title-row-1">
                            <img :src="require('@/assets/web.png')" width="64">
                        </div>
                        <div class="offer-title-row-2">
                            <h4>Web Development</h4>
                        </div>
                    </div>
                    <div class="offer-description">
                        <p>Individualentwicklungen für Webseiten oder Apps</p>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="section open-source">
        <div class="section-band">
            <div class="section-col"></div>
            <div class="section-col"></div>
        </div>
        <div class="section-title">
            <h2> Open S<a @click="toggleInfra">o</a>urce </h2>
        </div>
        <div class="open-source-content">
            <p>Wir sehen uns als Verfechter von Open Source Software, selbst bauen wir einen großen Teil unserer Infrastruktur darauf auf.</p>
            <p>Open Source hat neben Enterprise Produkten seinen Platz im Markt gefunden und je nach Kundenanforderung kann das eine oder andere mehr Sinn machen, entsprechend sind wir darauf bedacht, für jede Anforderung mindestens eine Lösung als Open Source und eine als Enterprise-Produkt für unsere Kunden verfügbar zu haben.</p>
        </div>
    </div>

    <div class="section request-content">
        <div class="section-band">
            <div class="section-col"></div>
            <div class="section-col"></div>
        </div>
        <div class="section-title">
            <h2> Gibt es ein Projekt, bei dem wir unterstützen können?</h2>
        </div>
        <div class="contact" >
            <button 
                class="contact-button"
                :class="[`contact-button-${theme}`]"
                @click.prevent="openMail"
            >Kontaktiere uns!</button>
        </div>
    </div>

</div>

<div v-if="showModal()" class="overlay" @click="disableModal"></div>

<div v-if="showNetwork" :class="[ 'modal' ]" @click.prevent="disableModal">
    <modal-component @click.stop :class="[ 'network-modal', `modal-${theme}` ]">
        <network-contents></network-contents>
    </modal-component>
</div>
<div v-if="showSecurity" :class="[ 'modal' ]" @click.prevent="disableModal">
    <modal-component @click.stop :class="[ 'security-modal', `modal-${theme}` ]">
        <security-contents></security-contents>
    </modal-component>
</div>
<div v-if="showInfra" :class="[ 'modal' ]" @click.prevent="disableModal">
    <modal-component @click.stop :class="[ 'infra-modal', `modal-${theme}` ]">
        <infra-contents></infra-contents>
    </modal-component>
</div>
<div v-if="showConsulting" :class="[ 'modal' ]" @click.prevent="disableModal">
    <modal-component @click.stop :class="[ 'consulting-modal', `modal-${theme}` ]">
        <consulting-contents></consulting-contents>
    </modal-component>
</div>

</template>

<script>
import { ref, inject, onMounted, onUnmounted, watchEffect, onBeforeUnmount } from 'vue';
import ModalComponent from '@/components/ModalComponent.vue';
import InfraContents from '@/components/InfraContents.vue';
import SecurityContents from '@/components/SecurityContents.vue';
import NetworkContents from '@/components/NetworkContents.vue';
import ConsultingContents from '@/components/ConsultingContents.vue';

export default {
    components: { ModalComponent, NetworkContents, SecurityContents, InfraContents, ConsultingContents },
    setup() {
        const $news = inject('$news', {});
        const theme = ref(localStorage.getItem('theme') || 'light');
        const latestNews = ref({
            title:   '',
            date:    '',
            content: ''
        });
        const $bus = inject('$bus');
        const showNetwork    = ref(false);
        const showSecurity   = ref(false);
        const showInfra      = ref(false);
        const showConsulting = ref(false);

        latestNews.value = $news.getLatestNews();

        const handelESC = (event) => {
            if(showModal() && event.key === 'Escape'){
                disableModal();
            }
        };

        function openMail() {
            const recipient = "Thrill IT GmbH <office@thrillit.at>";
/*            const subject = '' //encodeURIComponent("Anfrage...");
            const body = '' //encodeURIComponent("Guten Tag,\nich hätte folgende Anfrage:");
            // ?subject=${subject}&body=${body} */
            window.location.href = `mailto:${recipient}`;
        }

        function toggleInfra() {
            showInfra.value = !showInfra.value;
        }

        function showModal() {
            return (showInfra.value | showNetwork.value | showConsulting.value | showSecurity.value);
        }

        function disableModal() {
            showNetwork.value = false;
            showSecurity.value = false;
            showInfra.value = false;
            showConsulting.value = false;
            console.log("TEST")
        }

        onMounted(() => {
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
            $bus.$on('open-network', () => {
                showNetwork.value = true;
            });
            $bus.$on('open-security', () => {
                showSecurity.value = true;
            });
            $bus.$on('open-infra', () => {
                showInfra.value = true;
            });
            $bus.$on('open-consulting', () => {
                showConsulting.value = true;
            });
            $bus.$on('closeModal', disableModal);
            document.addEventListener('keydown', handelESC);
        });

        onBeforeUnmount(() => {
            document.removeEventListener('keydown', handelESC);
        });

        watchEffect(() => {
            if (showModal()) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        });

        return { latestNews, theme, 
                 showNetwork, showSecurity, showInfra, showConsulting,
                 openMail, toggleInfra, showModal };
    },
};
</script>



<style scoped>

.section {
    align-content: center;
    margin: 50px 0;
    border-top: 2px solid #999;
    border-radius: 10px;
    padding: 5px;
}

.section-band {
    display: flex;
    width: 100%;
    height: 32px;
    margin-top: 20px
}
.section-col {
    flex: 1 1 30%;
    padding-left: 10px;
    padding-right: 10px;
}
.section-col:not(:last-child) {
    border-right: 4px solid #888;
}
.section-title {
    text-align: center;
}
.section-title h2 {
    font-size: 40px !important;
}

.news-content {
    padding: 20px;
    margin: 10px;
}



.title-container {
    position: relative;
    width: 100%;
}
.image-logo {
    position: absolute;
    left: 0;
}
.image-network {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-left: 512px;
    padding-right: 10px;
    height: 100%;
}

.company-description {
    margin-top: -140px;
    margin-bottom: 20px;
    padding-bottom: 60px;
    border-bottom: 2px solid #999;
    border-radius: 10px;
}

@media (max-width: 1000px) {
    .image-logo {
        position: relative;
        left: 0;
    }
    .image-network {
        display: block;
        padding-left: 0;
        justify-content: left;
        position: absolute;
        left: 0;
    }
    .company-description {
        margin-top: 380px;
        padding-bottom: 0;
    }
}

.offer-grid {
    width: 100%;
    justify-content: center;
    align-content: center;
    padding: 10px;
}
.offer-row {
    display: flex;
    flex-wrap: nowrap;
    height: 256;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
    max-width: 1340px;
}
.offer-col {
    flex: 1 1 30%px;
    min-width: 300px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
    animation-name: fadeInUp;
}
@media (max-width: 1000px) {
    .offer-row {
        display: block;
        height: 256;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 5px;
        max-width: 1340px;
    }
    .offer-col {
        width: 100%;
    }
}

.offer-title {
    display: flex;
    width: 100%;
}
.offer-title-row-1 {
    flex: 1 1 25%;
}
.offer-title-row-2 {
    flex: 1 1 75%;
    align-content: center;
}

.contact {
    align-content: center;
    text-align: center;
}
.contact-button {
    background-color: #AAA;
    border-radius: 5px;
    align-content: center;
    font-size: large;
}
.contact-button-dark {
    background-color: #888;
}
.contact-button-dark:hover {
    box-shadow: 5px 5px 5px #AAA inset ;
}
.contact-button-light:hover {
    box-shadow: 5px 5px 5px #888 inset ;
}





.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
}

.modal {
    display: block ;
    visibility: visible;
    opacity: 1;
    z-index: 1002;
}
.modal-light{
    background-color: #AAA;
}

.infra-modal{
    z-index: 10;
}

</style>