<template>
    <div :class="[`modal-content-${theme}`]">
        <h1>IT Consulting</h1>
        <div class="consult-paragraph para1"><p>
            Consulting in der IT bezieht sich auf die Beratung, die Unternehmen in Fragen der Informationstechnologie unterstützt. Wir helfen unseren Kunden dabei, ihre IT-Infrastruktur zu optimieren, Softwarelösungen zu implementieren, Cybersecurity-Maßnahmen zu verbessern und digitale Transformationsprojekte zu planen und umzusetzen. Wir bringen unser jahrelanges Fachwissen in diesen Technologiebereichen mit und bieten maßgeschneiderte Lösungen an, die den individuellen Bedürfnissen unserer Kunden entsprechen. Unser Ziel ist es, die Effizienz, Sicherheit und Wettbewerbsfähigkeit der IT-Systeme der Kunden zu steigern.
        </p></div>
        <div class="consult-paragraph para2"><p>
            Unser Ziel ist, unseren Kunden einen Mehrwert zu bringen, indem wir tiefgehendes technisches Wissen und Erfahrung in verschiedenen IT-Bereichen bereitstellen. Wir analysieren bestehende IT-Systeme, identifizieren Schwachstellen und entwickelt Strategien zur Optimierung. Darüber hinaus unterstützten wir unsere Kunden bei der Implementierung neuer Technologien und sorgen so dafür, dass diese nahtlos in die bestehenden Strukturen integriert werden. Dies ermöglicht es unseren Kunden, sich auf ihre Kernkompetenzen zu konzentrieren, während sie sicher sein können, dass ihre IT-Systeme zuverlässig, sicher und zukunftssicher sind.
        </p></div>
        <div class="consult-paragraph para3"><p>
            Wir können jeden Aspekt die unser Kunde benötigt gerne übernehmen, sei es eine reine Beratung, eine Begleitung eines Projekts oder auch eine bestimmte Rolle in einem Projekt.
        </p></div>
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';

export default {
    setup() {
        const theme = ref(localStorage.getItem('theme') || 'light');
        const $bus = inject('$bus');
        
        onMounted(() => { 
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
        });

        return { name: 'ConsultingContents',
                 theme
               }
    },
}
</script>

<style scoped>

.consult{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 700px;
    background-color: rgba(128, 128, 132, 0.1);
    box-shadow: 0 4px 6px rgba(0,0,0,0.2) inset;
    border-radius: 8px;
}
.consult-paragraph{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
}
@media (max-width: 768px) {
    .consult {
        padding: 15px;
        gap: 15px;
    }

    .consult-paragraph {
        text-align: left;  /* Aligns text to the left on smaller screens */
    }
}

.modal-content-light{
    background-color: #DDD;
}

</style>