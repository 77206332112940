<template>
    <li>
        <router-link
            class="nav-link"
            active-class="active"
            aria-current="page" 
            :to="`/${index}`"
        >{{ page.link.text }}</router-link>
    </li>
</template>

<script>
export default {
    props: ['page','index'],
}
</script>

<style scoped>
.emphasize {
    text-decoration: underline !important;
}
</style>