import {createRouter, createWebHashHistory} from 'vue-router';
import Home from '@/views/Home.vue';
import Impressum from '@/views/Impressum.vue';
import News from '@/views/News.vue';
import SingleNews from '@/views/SingleNews.vue';
import Partner from '@/views/Partner.vue';

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        { path: '/', component: Home },
        { path: '/news',
            children: [
                { path: '',       component: News },
                { path: ':index', component: SingleNews },
            ]
        },
        { path: '/impressum', component: Impressum },
        { path: '/partner',   component: Partner },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 }
        }
    }
});

export default router;

