<template>

<div id="impressum-content" class="container">
    <h1>Impressum und Informationen gemäß $ 5 ECG</h1>
    <hr>
    <h5>Medieninhaber und Herausgeber: </h5>
    <p>
         Thrill IT GmbH<br/>
         Körnergasse 42<br/>
         2170 Poysdorf<br/>
         Geschäftsführer: Ing. Michael Friedl
    </p>
    <p>
        Telefon: +43 699 11 955708<br/>
        E-Mail: <a class="courier-font" href="mailto:office@thrillit.at">office@thrillit.at</a>
    </p>
    <p>
        Sitz der Gesellschaft: Poysdorf<br/>
        Registriert: Landesgericht Korneuburg<br/>
        FN 629937d<br/>
        UID ATU 80875956
    </p>
    <p>
        Grundlegende Richtung der Website (Blattlinie):<br/>
        Information über Waren und Dienstleistungen des Unternehmens, sowie Förderung des Absatzes derselben.
    </p>
    <p>
        Unternehmensgegenstand: <br/>
        Vertrieb von Soft- und Hardware auf dem Gebiet der Informationstechnologie(IT) und Operational Technology(OT); die Planung, Entwicklung und der Vertrieb von Software und Teilen von Software aller Art; die Durchführung von Einrichtungs-, Wartungs- und Reparaturarbeiten sowie der Handel mit Waren aller Art und die Ausübung aller Gewerbe, die sich daraus ergeben.
    </p>
</div>

</template>

<style scoped>

.impressum-content{
    width: 800px;
}

</style>