<template>
<div id="news-content" class="container">
    <div class="return-content">
        <button 
            class="return-button"
            :class="[`return-button-${theme}`]"
            @click.prevent="returnToNews"
        >Zurückzu den News</button>
    </div>
    <h1>{{ news.title }}</h1>
    <h3>{{ news.date }}</h3>
    <div class="news-content" v-html="news.content"></div>
</div>
</template>


<script>
import { onMounted, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
    setup() {
        const $news = inject('$news');
        const $bus  = inject('$bus');
        const route = useRoute();
        const router= useRouter();
        const index = ref(route.params.index);
        const news  = ref($news.getNewsByIdentifier(index.value));
        const theme = ref(localStorage.getItem('theme') || 'light');

/*        onMounted(() => { 
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
        }); //*/

        function returnToNews(){
            let i = Math.round($news.getAllNews().findIndex(item => item.identifier == news.value.identifier)/10)*10;

            if( i !=  0 ) {
                router.push({path: '/news', query: { start: i } });
            } else {
                router.push({path: '/news'});
            }
        }
        
        onMounted(() => { 
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
        });

        return { theme, news, returnToNews };
    },
}
</script>

<style scoped>

.return-content {
    margin-top: 10px;
}

.return-button {
    border-radius: 10px;
}

.return-button-dark {
    background-color: #888;
}
.return-button-light {
    background-color: #AAA;
}
.return-button-dark:hover {
    box-shadow: 5px 5px 5px #AAA inset ;
}
.return-button-light:hover {
    box-shadow: 5px 5px 5px #888 inset ;
}

</style>