<template>
    <div :class="[`modal-content-${theme}`]">
        <h1>Netzwerklösungen</h1>
        <div class="network-paragraph para1"><p>
            Wir planen, installieren und betreiben Lösungen von diversen Netzwerkausrüstern, eine kurze Auswahl der größten in unserem Portfolio:
            <ul>
                <li>Cisco</li>
                <li>Fortinet</li>
                <li>Palo Alto</li>
                <li>Siemens</li>
            </ul>
            Uns sind die teils komplett unterschiedlichen Anforderungen in IT und OT gut vertraut und können so unseren Kunden eine möglichst gut auf ihre Anforderungen zugeschnittene Lösung bieten.
        </p></div>
        <div class="network-paragraph para2"><p>
            Neben der Ausrüstung ansich betreuen wir selbstverständlich auch in der Konfiguration und Administration der Geräte. Netzwerkverkabelung von Kupfer- oder Glasfaserkabeln ist ebenso Teil unseres Portfolios.
        </p></div>
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';

export default {
    setup() {
        const theme = ref(localStorage.getItem('theme') || 'light');
        const $bus = inject('$bus');
        
        onMounted(() => { 
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
        });

        return { name: 'NetworkContents',
                 theme}
    },
}
</script>

<style scoped>

.network{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 700px;
    background-color: rgba(128, 128, 132, 0.1);
    box-shadow: 0 4px 6px rgba(0,0,0,0.2) inset;
    border-radius: 8px;
}
.network-paragraph{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
}
@media (max-width: 768px) {
    .network {
        padding: 15px;
        gap: 15px;
    }

    .network-paragraph {
        text-align: left;  /* Aligns text to the left on smaller screens */
    }
}

.modal-content-light{
    background-color: #DDD;
}

</style>