<template>
    <div :class="`global-theme-${theme}`">
        <navbar></navbar>

        <router-view></router-view>

        <thrill-footer></thrill-footer>
    </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import ThrillFooter from './components/Footer.vue';
import { ref, onMounted, inject, watchEffect } from 'vue';

export default {

    setup() {
        const theme = ref(localStorage.getItem('theme') || 'light');
        const modalTitle = ref("Thrill IT GmbH")
        const $bus = inject('$bus');
        const $news = inject('$news');

        function handleThemeChange(event) {
            theme.value = localStorage.getItem('theme') || 'light' ;
        }

        onMounted(() => {
            $bus.$on('theme-change', handleThemeChange);
            $news.fetchNews();
        });
        watchEffect(() => {
            document.title = modalTitle.value;
        });

        return { theme };
    },

    components: {
        Navbar,
        ThrillFooter,
    },
    
};
</script>

<style>
@font-face {
    font-family: 'sabado';
    src: url('@/assets/fonts/sabado.otf') format('otf');
}

body {
    padding-top: 64px;
}

#app {
    font-family: 'sabado', sans-serif;
}

.blur {
    filter: blur(2px);
    pointer-events: none; /* Disables interactions */
}

.no-scroll {
    overflow: hidden;
}

.global-theme-light {
    background-color: #DDD;
    color: #444;
    margin-bottom: 7px;
}
.global-theme-dark {
    background-color: #444;
    color: #BBB;
    margin-bottom: 7px;
}

.courier-font{
    font-family: 'Courier New', Courier, monospace;
    font-size: small;
}

.inline-link {
    display: inline !important;
}

.box {
    border: 2px solid #000;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
}

.theme-box-dark {
    background: #666;
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-bottom-color: #333;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-style: solid;
    border-top-width: 5px;
    border-top-color: #333;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.theme-box-light {
    background: #AAA;
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-bottom-color: #555;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-style: solid;
    border-top-width: 5px;
    border-top-color: #555;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
</style>