import { createApp, reactive } from "vue";
import App from './App.vue';
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import $bus from './utils/Events';
import router from "./utils/routes";
import news from './data';

const app = createApp(App);
const $news = reactive(news);

app.use(router);

app.provide('$news', $news);
app.provide('$bus', $bus);

app.mount('#app');