<template>

<div id="news-content" :class="[ 'container', {'blur': showModal() }]">
    <h1>Neuigkeiten</h1>
    <div class="container btn-container">
        <button 
            class="left-button button"
            :class="[`button-${theme}`]"
            @click.prevent="shift(-10)"
        >-10</button>
        <button 
            class="right-button button"
            :class="[`button-${theme}`]"
            @click.prevent="shift(10)"
        >+10</button>
        <div class="info">
            <div class="top-center">
                <p>Erster Index: {{ index }}</p>
            </div>
        </div>
    </div>
    <div :class="['news-box', `theme-box-${theme}`]" v-for="entry in news" >
        <a class="news-button" :href="`#/news/${entry.identifier}`">
            <div class="news-title">
                <h4>{{ entry.date }} - {{ entry.title }}</h4>
            </div>
            <div class="news-content" v-html="entry.content"></div>
        </a>
    </div>

    <div class="container btn-container">
        <button 
            class="left-button button"
            :class="[`button-${theme}`]"
            @click.prevent="shift(-10)"
        >-10</button>
        <button 
            class="right-button button"
            :class="[`button-${theme}`]"
            @click.prevent="shift(10)"
        >+10</button>
    </div>
</div>

<div v-if="showModal()" class="overlay" @click="disableModal"></div>

<div v-if="showNetwork" class="modal" @click.prevent="disableModal">
    <modal-component @click.stop class="network-modal">
        <network-contents></network-contents>
    </modal-component>
</div>
<div v-if="showSecurity" class="modal" @click.prevent="disableModal">
    <modal-component @click.stop class="security-modal">
        <security-contents></security-contents>
    </modal-component>
</div>
<div v-if="showInfra" class="modal" @click.prevent="disableModal">
    <modal-component @click.stop class="infra-modal">
        <infra-contents></infra-contents>
    </modal-component>
</div>
<div v-if="showConsulting" class="modal" @click.prevent="disableModal">
    <modal-component @click.stop class="consulting-modal">
        <consulting-contents></consulting-contents>
    </modal-component>
</div>

</template>

<script>
import { inject, ref, watch, onMounted, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ModalComponent from '@/components/ModalComponent.vue';
import InfraContents from '@/components/InfraContents.vue';
import SecurityContents from '@/components/SecurityContents.vue';
import NetworkContents from '@/components/NetworkContents.vue';
import ConsultingContents from '@/components/ConsultingContents.vue';

export default {
    components: { ModalComponent, NetworkContents, SecurityContents, InfraContents, ConsultingContents },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const $news = inject('$news');
        const $bus  = inject('$bus');
        const index = ref(route.query.start || 0);
        const news  = ref($news.getNews10(index.value));
        const theme = ref(localStorage.getItem('theme') || 'light');
        const showNetwork    = ref(false);
        const showSecurity   = ref(false);
        const showInfra      = ref(false);
        const showConsulting = ref(false);

        function shift(amount){
            let newIndex = index.value + amount;
            index.value = Math.max(Math.min(newIndex, Math.round(($news.count()-1)/10)*10), 0);
            if(index.value > 0) {
                router.push({query: {start: index.value} }).catch(err => {
                    if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                        console.error(err);
                    }
                });
            } else {
                router.push({});
            }
        }
        
        const handelESC = (event) => {
            if(showModal() && event.key === 'Escape'){
                disableModal();
            }
        };

        function showModal() {
            return showInfra.value || showNetwork.value || showConsulting.value || showSecurity.value;
        }

        function disableModal() {
            showNetwork.value = false;
            showSecurity.value = false;
            showInfra.value = false;
            showConsulting.value = false;
        }

        onMounted(() => { 
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
            $bus.$on('open-network', () => {
                showNetwork.value = true;
            });
            $bus.$on('open-security', () => {
                showSecurity.value = true;
            });
            $bus.$on('open-infra', () => {
                showInfra.value = true;
            });
            $bus.$on('open-consulting', () => {
                showConsulting.value = true;
            });
            $bus.$on('closeModal', disableModal);
            document.addEventListener('keydown', handelESC);
        });

        watch(() => route.query.start, (newIndex) => {
            if(newIndex) {
                index.value = Math.max(Math.min(newIndex, Math.round(($news.count()-1)/10)*10), 0);
            } else {
                index.value = 0;
            }
        });

        watch(index, (newValue, oldValue) => {
            news.value = $news.getNews10(newValue);
        });

        watchEffect(() => {
            if (showModal()) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        });

        return { index, news, theme, 
                 showNetwork, showSecurity, showInfra, showConsulting,
                 shift, showModal,
        };
    },
}
</script>





<style scoped>
.container {
    position: relative;
    width: 100%;
}

.btn-container {
    height: 30px;
    z-index: 10;
}

.button {
    background-color: darkgrey;
    border-radius: 5px;
}

.left-button {
    position: absolute;
    left: 0;
}

.right-button {
    position: absolute;
    right: 0;
}

.info {
    height: 32px;
    margin-left: 35px;
    margin-right: 35px;
    min-width: 350px;
    max-height: 32px;
    z-index: 0;
}

.top-center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-button {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.news-box {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    max-height: 95px;
    overflow: hidden;
}

.button-dark {
    background-color: #888;
}
.button-light {
    background-color: #AAA;
}

.theme-box-dark:hover, .button-dark:hover {
    box-shadow: 5px 5px 5px #AAA inset ;
}
.theme-box-light:hover, .button-light:hover {
    box-shadow: 5px 5px 5px #888 inset ;
}




.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    justify-content: center;
    align-items: center;
}

.modal {
    display: block ;
    visibility: visible;
    opacity: 1;
    z-index: 1002;
}

.infra-modal{
    z-index: 10;
}

</style>