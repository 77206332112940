<template>
    <div :class="['container', 'overlay-1']">
        <div class="topwindowbar">
            <img class="logo-short" :src="require('@/assets/Logo_short.png')" height="24">
            <form class="d-flex">
                <a 
                    class="close-button"
                    @click.prevent="closeButton()">
                    <img 
                        :src="require('@/assets/close.png')" 
                        width="24">
                </a>
            </form>
        </div>
        <div :class="['container', 'overlay-2', `overlay-${theme}`]">
            <slot></slot>
        </div>
    </div>
</template>
  
<script>
import { ref, inject, onMounted } from 'vue';

export default {
    setup() {
        const theme = ref(localStorage.getItem('theme') || 'light');
        const $bus = inject('$bus');

        function closeButton() {
            $bus.$emit('closeModal');
        }

        onMounted(() => { 
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
        });

        return { name: 'ModalComponent', 
                 theme,
                 closeButton}
    }
};
</script>
  


<style scoped>

.topwindowbar{
    padding-bottom: 4px;
    display: flex;
    justify-content: space-between;
}
.logo-short {
    margin-top: 4px;
    margin-left: 10px;
}
.close-button{
    margin-left: 5px;
    cursor: pointer;
}

.overlay-1{
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 10px;
    padding: 5px;
    padding-bottom: 5px;
    padding-top: 0px;
    max-width: 700px;
    background-color: #888;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}

.overlay-light{
    background-color: #DDD;
    box-shadow: none;
}

.overlay-2{
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 700px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.2) inset;
    border-radius: 3px;
}
</style>