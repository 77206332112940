<template>
    <div :class="[`modal-content-${theme}`]">
        <h1>Infrastruktur</h1>
        <div class="infra-paragraph para1"><p>
            Hier wird alles umfasst, das zum Betrieb eines IT-Netzes benötigt wird. Neben den Netzwerken, die ein Teilbereich sind und oft als selbstständig angesehen werden, zählen hier Hardware wie Server, Serverschränke, Computer/Laptops, Backup, NAS/SAN-Speicher aber auch Software wie Betriebssysteme an sich, aber auch Datenbanken, Monitoring, Ticketing, CMS, ERP,...
        </p></div>
        <div class="infra-paragraph para2"><p>
            Bei Servern, Schränken und Compuern sind wir komplett Hersteller-agnostisch und können gerne den vom Kunden gewünschten Hersteller nutzen.
        </p></div>
        <div class="infra-paragraph para3"><p>
                Bei Software haben wir mit einigen Herstellern bereits Erfahrung gesammelt und können entsprechend unsere Unterstützung bei diesen zusichern. Folgend eine Auswahl in welchem Teilbereich wir welchen Hersteller betreuen:
            </p>
            <table class="infra-table">
                <thead>
                    <tr>
                        <th>System</th>
                        <th>Enterprise-Lösung</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Asset Management</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Monitoring</td>
                        <td>Paessler PRTG</td>
                    </tr>
                    <tr>
                        <td>Ticketing</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Vulnerability Management</td>
                        <td>Tenable Nessus</td>
                    </tr>
                    <tr>
                        <td>Wissensmanagement/Wiki</td>
                        <td>Phonemos</td>
                    </tr>
                </tbody>
            </table>
            (Tabelle ist nicht vollständig, wird im Anlassfall erweitert)
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';

export default {
    setup() {
        const theme = ref(localStorage.getItem('theme') || 'light');
        const $bus = inject('$bus');
        
        onMounted(() => { 
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
        });

        return { name: 'InfraContents',
                 theme
               }
    },
}
</script>

<style scoped>

.infra-paragraph{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
}

@media (max-width: 768px) {
    .infra-paragraph {
        text-align: left;  /* Aligns text to the left on smaller screens */
    }
}

.infra-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 650px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.infra-table thead tr {
    background-color: #006849;
    color: #ffffff;
    text-align: left;
}

.infra-table th,
.infra-table td {
    padding: 12px 15px;
}

.infra-table tbody tr {
    border-bottom: 1px solid #009879;
}

.infra-table tbody tr:nth-of-type(even) {
    background-color: #777;
    color: #BBB;
}

.infra-table tbody tr:last-of-type {
    border-bottom: 2px solid #006849;
}

.infra-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.infra-table tbody tr:hover {
    background-color: #f1f1f1;
    color: #444;
}

.modal-content-light{
    background-color: #DDD;
}

</style>