import { nextTick } from "vue";

const newsKey = 'news';

let newsJson = localStorage.getItem(newsKey);
let newsStore = JSON.parse(newsJson);

export default {
    getAllNews() {
        return newsStore;
    },

    getSingleNews(index) {
        return newsStore[index];
    },

    getLatestNews() {
        return this.getNews10(0)[0];
    },

    getNewsByIdentifier(id) {
        return newsStore.find(news => news.identifier === id);
    },

    count() {
        return newsStore.length;
    },

    getNews10(start) {
        let dummy={
            title:      '',
            date:       '',
            content:    '',
            identifier: ''
        };
        if(!newsStore){
            return {dummy};
        }
        if(start>newsStore.length-1){
            return {dummy};
        }
        return newsStore.slice(start,start+10);
    },

    async fetchNews() {
        try{
            const response = await fetch('news.json', {
                mode: 'no-cors',
                method: 'GET'
            });
            if(!response.ok) {
                throw new Error('Failed to fetch News: ', response);
            }
            newsStore = (await response.json());
            localStorage.setItem(newsKey, JSON.stringify(newsStore));
        } catch(err) {
            alert(err.message);
        }
        await nextTick();
    }
}