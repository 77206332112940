<template>
    <nav :class="[`navbar-${theme}`, `bg-${theme}`, 'navbar', 'navbar-expand-lg', 'fixed-top', {'disabled': disabled }]">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img :src="require('@/assets/Logo.png')" alt="Thrill IT" width="100">
            </a>
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li>
                    <router-link
                        class="nav-link"
                        active-class="active"
                        aria-current="page" 
                        to="/news"
                    >News</router-link>
                </li> 
            </ul>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown"
                        @mouseover="hover_sol = true" 
                        @mouseleave="hover_sol = false">
                        <a class="nav-link dropdown-toggle" href="#" 
                            @click.prevent="noop" 
                            aria-haspopup="true" 
                            :aria-expanded="hover_sol.toString()">
                            Lösungen
                        </a>
                        <ul class="dropdown-menu" 
                            :class="[`dropdown-${theme}`]"
                            v-show="hover_sol">
                            <li><a :class="['dropdown-item', `dropdown-item-${theme}`]" @click="openNetwork">Netzwerk</a></li>
                            <li><a :class="['dropdown-item', `dropdown-item-${theme}`]" @click="openSecurity">IT/OT Security</a></li>
                            <li><a :class="['dropdown-item', `dropdown-item-${theme}`]" @click="openInfra">Infrastruktur</a></li>
                            <li><a :class="['dropdown-item', `dropdown-item-${theme}`]" @click="openConsulting">Consulting</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <form class="d-flex">
                <a 
                  class="toggle"
                  @click.prevent="changeTheme()">
                    <img 
                        :src="require('@/assets/L_D.png')" 
                        width="32">
                    <div class="tooltip" style="tooltipStyle" v-if="tooltipIsVisible">Light/Dark Mode</div>
                </a>
            </form>
        </div>
    </nav>
</template>

<script>
import { inject, onMounted, ref, defineComponent, watch } from 'vue';
import NavbarLink from './NavbarLink.vue';

export default defineComponent({
    components: {
        NavbarLink
    },
    props: [ 'disabled' ],
    setup() {
        const tooltipIsVisible = ref(false);
        const tooltipStyle = ref({
            position: 'fixed', 
            backgroundColor: 'black',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '4px',
            visibility: 'hidden',
            zIndex: '1080',
            right: '0px',
            top: '0px',
            opacity: '0'
        });
        const $bus = inject("$bus");
        const theme = ref('light');
        const hover_sol = ref(false);
        const noop = () => {};


        function showTooltip() {
            tooltipIsVisible.value = true;
            tooltipStyle.value.visibility = 'visible';
            tooltipStyle.value.opacity = '1';
        }

        function hideTooltip() {
            tooltipIsVisible.value = false;
            tooltipStyle.value.visibility = 'hidden';
            tooltipStyle.value.opacity = '0';
        }

        function updateTooltip(event) {
            tooltipStyle.value.left = `${event.clientX}px`;
            tooltipStyle.value.top = `${event.clientY + 20}px`; // 20px below the cursor
        }

        function openNetwork() {
            closeSubmenu();
            $bus.$emit('open-network');
        }
        function openSecurity() {
            closeSubmenu();
            $bus.$emit('open-security');
        }
        function openInfra() {
            closeSubmenu();
            $bus.$emit('open-infra');
        }
        function openConsulting() {
            closeSubmenu();
            $bus.$emit('open-consulting');
        }
        function closeSubmenu() {
           hover_sol.value = false;
        }

        function changeTheme() {
            theme.value = theme.value=='dark' ? 'light' : 'dark';
            storeThemeSetting();
        }
        function storeThemeSetting() {
            localStorage.setItem('theme',theme.value);
            $bus.$emit('theme-change');
        }
        function getThemeSetting() {
            theme.value = localStorage.getItem('theme') || 'light';
        }

        onMounted( () => {
            getThemeSetting();
            $bus.$emit('theme-change');
        });

        return { tooltipIsVisible, tooltipStyle, theme, hover_sol, 
            noop, changeTheme, storeThemeSetting, getThemeSetting,
            showTooltip, hideTooltip, updateTooltip, closeSubmenu,
            openInfra, openNetwork, openSecurity, openConsulting,
        };
    }
});
</script>

<style scoped>
.toggle {
    cursor: pointer;
}

.nav-item .dropdown-menu {
  display: none;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-light, .dropdown-item-dark:hover {
    background-color: #CCC;
    color: #000;
}
.dropdown-dark, .dropdown-item-light:hover {
    background-color: #888;
    color: #DDD;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

</style>