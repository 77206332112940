<template>
    <div :class="[`modal-content-${theme}`]">
        <h1>IT/OT Sicherheit</h1>
        <div class="security-paragraph para1"><p>
            Security ist so wichtig wie nie zuvor und das zeigt sich auch immer öfter in den Nachrichten. 
            In der IT gibts es zwei Arten der Sicherheit: Security und Safety, wobei Safety primär in der OT interessant ist, wie unten erklärt. 
        </p></div>
        <div class="security-paragraph para2"><p>
            Security ist der Schutz der Maschine vor Menschen - sei es Hacking, unberechtigter Zugriff oder Schwachstellen die geschlossen werden müssen. hir könen wir unter anderen mit Firewalls, Load-Balancers, Intrusion Detection/Prevention System, Asset-Management, Vulnerability-Manaement gut Abhilfe schaffen.
        </p></div>
        <div class="security-paragraph para3"><p>
            Safety ist der Schutz des Menschen vor der Maschine - in der produzierenden Industrie gibt es oft große roboter und Maschinen, die bei falscher Bedienung oder im Fehelrfall Gefahr für Mensch und Leben darstellen können. Diesen Gefahren ist nicht immer allein technisch beizukommen, gefährliche Maschinen müssen im Betrieb entsprechend abgesichert werden und alle Mitarbeiter benötigen das nötige Wissen, sich nicht selbst in Gefahr zu bringen.
        </p></div>
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';

export default {
    setup() {
        const theme = ref(localStorage.getItem('theme') || 'light');
        const $bus = inject('$bus');
        
        onMounted(() => { 
            $bus.$on('theme-change', () => {
                theme.value = localStorage.getItem('theme') || 'light';
            });
        });

        return { name: 'SecurityContents',
                 theme
               };
    },
}
</script>

<style scoped>

.security{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 700px;
    background-color: rgba(128, 128, 132, 0.1);
    box-shadow: 0 4px 6px rgba(0,0,0,0.2) inset;
    border-radius: 8px;
}
.security-paragraph{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
}
@media (max-width: 768px) {
    .security {
        padding: 15px;
        gap: 15px;
    }

    .security-paragraph {
        text-align: left;  /* Aligns text to the left on smaller screens */
    }
}

.modal-content-light{
    background-color: #DDD;
}

</style>